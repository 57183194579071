import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {Page404} from "../pages/Page404";
import {Ask} from "../pages/Ask";
import {Shop} from "../pages/Shop";

export function Router() {


    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/ask" element={<Ask />} />
                    <Route exact path="/shop" element={<Shop />} />
                    <Route exact path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}