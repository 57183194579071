import {useEffect} from "react";

export function Shop() {
    useEffect(() => {
        // Configura l'oggetto spread_shop_config
        const spreadShopConfig = {
            shopName: 'sarannopurecazzimiei',
            locale: 'en_IT',
            prefix: 'https://sarannopurecazzimiei.myspreadshop.it',
            baseId: 'myShop'
        };

        // Crea il contenuto HTML da inserire con dangerouslySetInnerHTML
        const scriptContent = `
            var spread_shop_config = ${JSON.stringify(spreadShopConfig)};
        `;

        // Aggiungi lo script al DOM
        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = scriptContent;
        document.body.appendChild(scriptElement);

        // Crea un elemento script per caricare il file JavaScript
        const shopClientScript = document.createElement('script');
        shopClientScript.src = "https://sarannopurecazzimiei.myspreadshop.it/shopfiles/shopclient/shopclient.nocache.js";
        shopClientScript.async = true;
        document.body.appendChild(shopClientScript);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(scriptElement);
            document.body.removeChild(shopClientScript);
        };
    }, []);

    return (
        <div>
            <div id="myShop">
                <a href="https://sarannopurecazzimiei.myspreadshop.it">sarannopurecazzimiei</a>
            </div>
        </div>
    );
}